* {
	margin: 0;
	padding: 0;
}
body {
	font-family: 'Montserrat', sans-serif;
	box-sizing: border-box;
	line-height: 1.5;
}
button {
	border: none;
}
html,body {
	max-width: 100%;
	overflow-x: hidden;
}
a {
	text-decoration: none;
}

h1,h2,h3,h4,h5,h6 {
	color: #616161;
}