.landingPhoto-l,
.landingPhoto-s {
	display: none;
}
.formImage {
	display: none;
}
/* small screens  */
@media only screen and (max-width: 450px) {
	.skills p {
		width: 100%;
	}
}

@media only screen and (min-width: 450px) {
	.skills p {
		width: 100%;
	}
}

@media only screen and (min-width: 650px) {
	.landingPhoto-s {
		display: inline-block;
		height: 250px;
		position: absolute;
		right: 40px;
		top: 270px;
		z-index: 12;
	}
	.skills div {
		width: 40%;
	}
}


@media only screen and (min-width: 900px) {
	.contact form {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	.contact input,
	.contact textarea {
		padding: 5px;
		border-radius: 5px;
		height: 30px;
		outline: none;
		width: 100%;
		border: solid 1px #2369ff42;
		font-size: 95%;
	}

	.contact label {
		color: rgba(0, 0, 0, 0.719);
		margin: 15px 0 0 0;
	}
	.contact textarea {
		height: 150px;
	}

	.button {
		background-color: #2368ff;
		color: white;
		padding: 10px;
		margin: 10px 0px;
		width: 105%;
		border-radius: 5px;
		font-size: 100%;
	}
	.formImage {
		display: inline-block;
		height: 300px;
		margin: 150px 0 0 100px;
	}
}



@media only screen and (min-width: 1050px) {
	.landingPhoto-s{
		display: none;
	}
	
	.landingPhoto-l {
		display:inline-block;
		height: 350px;
		position: absolute;
		right: 20px;
		top: 215px;
		z-index: 12;
	}	
}


.background-picture {
	background-image: url("../../img/background22.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	height: 100vh;
	margin-top: -30px;
}
a,
button {
	outline: none;
}


.h1 {
	margin: 200px 0 20px 0;
}


.landing-content p {
	color: rgba(243, 241, 241, 0.842);
	z-index: 20;
	margin-bottom:35px;
}


.about {
	margin: 400px 0 0 0;
	padding-bottom: 200px;
	color: black;
}

.name {
	display: flex;
	justify-content: center;
	font-weight: 700;
	font-size: 160%;
}
.col1 {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.aboutMe {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
}

.skills {
	width: 30%;
}

.skills p {
	margin: 10px 0 0 0;
}

.h3 {
	margin-top: 20px;
}
.social {
	margin: 15px 0 0 0;
	list-style-type: none;
}
.social a {
	margin: 6px;
}

.mt100 {
	margin: 100px 0 0 0;
}

.projectsSection {
	background-color: #edf3fc;
	padding: 0px 0px 20px 0px;
	margin-top: -10px;
}
.projectsSection h3 {
	color: black;
	font-size: 160%;
	text-align: center;
	margin-bottom: 100px;
}
.projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}
.blobProject {
	width: 100vw;
}

.contact {
	margin: 150px 0 0 0;
	color: black;
	display: flex;
	flex-direction: row;
}

.contact h3 {
	font-size: 150%;
	margin: 0 0 50px 0;
}
.contact form {
	display: flex;
	flex-direction: column;
	width: 80%;
}
.contact input,
.contact textarea {
	padding: 5px;
	border-radius: 5px;
	height: 30px;
	outline: none;
	width: 100%;
	border: solid 1px #2369ff42;
	font-size: 95%;
}

.contact label {
	color: rgba(0, 0, 0, 0.719);
	margin: 15px 0 0 0;
}
.contact textarea {
	height: 150px;
}

.button {
	background-color: #2368ff;
	color: white;
	padding: 10px;
	margin: 20px 0px;
	width: 105%;
	border-radius: 5px;
	font-size: 100%;
	transition:0.2s;
}
.button:hover{
	cursor: pointer;
	box-shadow: 3px 8px 15px rgba(178, 182, 241, 0.901);
	transform: translateY(-4px);
}
.formImage {
	height: 300px;
	margin: 150px 0 0 100px;
}
.mw-90p {
	margin: 0 10%;
}
.mt-30px {
	margin-top: 30px;
}
.mt-100px {
	margin-top: 100px;
}
.footer {
	margin-top: 150px;
	background-image: linear-gradient(#389bff, #0334ff);
	color: white;
	padding: 50px;
	text-align: center;
}
